import { sum, sumBy } from "lodash";

/**
 * CartSummary class is the information that displayed on the Cart page.
 */
export default class CartSummary {
    /**
     * @param {object}  param - Includes cartData, subscriptionData and currency.
     * @param {object} param.cartData - Details of lineitems in the cart.
     * @param {object} param.subscriptionData - Data from vuex.
     * @param {number} param.currency - Current coming from the cart.
     */
    constructor({ cartData, subscriptionData, currency }) {
        this.cart = cartData;
        this.$currency = currency;
        this.subscriptionData = subscriptionData;
    }
    /**
     *   @returns {Array} Summary listing for the item.
     */
    get items() {
        return this.cart.items.map((item) =>
            this._getItemSummary(item.id, item.price, item.quantity)
        );
    }
    /**
     * @returns {number} Subtotal Price.
     */
    get subtotalPrice() {
        return this.$currency(this.cart.subTotal).subtract(this.totalSubscriptionDiscount);
    }
    /**
     * @returns {number} Total Price.
     */
    get totalPrice() {
        return this.subtotalPrice.subtract(this.totalBigcommerceDiscount);
    }
    /**
     * @returns {boolean} If the cart is a subscription cart.
     */
    get isSubscriptionCart() {
        return this.items.some((i) => i.isSubscription);
    }
    /**
     * @returns {number} Subscription discount.
     */
    get totalSubscriptionDiscount() {
        return sumBy(this.items, (i) => i.discount.multiply(i.quantity).value);
    }
    /**
     * @returns {number} Sum of all taxes, total tax.
     */
    get totalTaxes() {
        return sum(this.cart.taxes);
    }
    /**
     * @returns {number} Discount via BigCommerce.
     */
    get totalBigcommerceDiscount() {
        return Math.abs(this.cart?.discount ?? 0);
    }
    /**
     * @returns {object} Item summary that includes : originalPrice, discount, id, quantity, lineTotal, price, isSubscription, isPrepaid, shippingFrequency, shippingUnit and chargeFrequency.
     * @param {string} itemLineId - Unique line item id.
     * @param {number} itemPrice - Price of the item.
     * @param {number} itemQuantity - Quantity or count of the item.
     */
    _getItemSummary(itemLineId, itemPrice, itemQuantity) {
        const itemSubData = this.subscriptionData.find((i) => i.line_item === itemLineId);
        const originalPrice = this.$currency(itemPrice);
        const discount = originalPrice.multiply(itemSubData?.discount_amount || 0).divide(100);
        return {
            originalPrice,
            discount,
            id: itemLineId,
            quantity: itemQuantity,
            lineTotal: originalPrice.subtract(discount).multiply(itemQuantity),
            price: originalPrice.subtract(discount),
            isSubscription: itemSubData?.shipping_frequency > 0 ?? false,
            isPrepaid: itemSubData?.isPrepaid ?? false,
            shippingFrequency: itemSubData?.shipping_frequency,
            shippingUnit: itemSubData?.shipping_unit,
            chargeFrequency: itemSubData?.charge_frequency,
        };
    }
}
