<template>
    <div id="cartSubscribe" :class="div.class">
        <!--        <br />-->
        <strong> {{ div.text }} </strong>
    </div>
</template>

<script>
export default {
    name: "CartLineItemSubscriptionLabel",
    props: {
        isDiscounted: Boolean,
        shippingFrequency: Number,
        shippingUnit: { type: [String, Number], default: "" },
        chargeFrequency: Number,
        isPrepaid: Boolean,
    },
    computed: {
        /**
         *
         */
        shippingUnitAsString() {
            const unit = this.shippingUnit ?? "";
            return unit.toString().toLowerCase();
        },
        /**
         * Calculates the correct plural.
         * @returns {string}
         */
        shippingUnitText() {
            const unitMap = this.unitMap(this.shippingUnitAsString);
            return this.shippingFrequency > 1 ? unitMap?.multiple : unitMap?.single;
        },
        /**
         * Based on discount amount chooses the correct
         * localized subscription frequency text.
         * @returns {string}
         */
        subscriptionMessage() {
            const text = this.isDiscounted
                ? this.$t("cart.cart_sub_save_frequency_text")
                : this.$t("cart.cart_sub_frequency_text");
            return `${text} ${this.shippingFrequency} ${this.shippingUnitText}.`;
        },
        /**
         * @returns {string} Will display the subscription message under product name.
         */
        prepaidMessage() {
            let message = `${this.$t("products.widget_deliver_every")} ${this.shippingFrequency} ${
                this.shippingUnitText
            }`;
            if (this.shippingFrequency !== this.chargeFrequency) {
                message = `${message}. ${this.$t("products.widget_charge_every")} ${
                    this.chargeFrequency
                } ${this.shippingUnitText}.`;
            }
            return message;
        },
        /**
         * @returns {object} Subscription message information.
         */
        div() {
            if (this.isPrepaid) {
                return {
                    class: "rca-cart-line-item-subscribe rca-prepaid-details",
                    text: this.prepaidMessage,
                };
            }
            return {
                class: "rca-cart-line-item-subscribe",
                text: this.subscriptionMessage,
            };
        },
    },
    methods: {
        /**
         * @returns {string} Unit message.
         * @param {string} unit - Property that will save the unit information.
         */
        unitMap(unit) {
            const _default = {
                day: "Day",
                days: "Days",
                week: "Week",
                weeks: "Weeks",
                month: "Month",
                months: "Months",
            };
            const merged = {
                ..._default,
                ...JSON.parse(this.settings.translations.products.shipping_interval_unit_type),
            };
            const final = {
                day: { single: merged.day, multiple: merged.days },
                days: { single: merged.day, multiple: merged.days },
                week: { single: merged.week, multiple: merged.weeks },
                weeks: { single: merged.week, multiple: merged.weeks },
                month: { single: merged.month, multiple: merged.months },
                months: { single: merged.month, multiple: merged.months },
            };

            return final[unit] || final.day;
        },
    },
};
</script>
