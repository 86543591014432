import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

const START = Date.now();

const logCss = {
    meta: "color: gray; font-weight: normal;",
    debug: "color: #788BFF;font-weight: bold;",
    info: "color: yellow; background: black; font-weight: bold;",
    current: "color: chartreuse; background: black; font-weight: bold;",
    warning: "color: orange; font-weight: normal;",
    error: "color: red; font-weight: normal;",
};

const prefixCss = {
    time: "color: coral; font-weight: normal;",
    location: "color: gray; font-weight: normal;",
};

/**
 *
 */
export class Logger {
    /**
     * @param {string|null} moduleName - The name of the module to be logged.
     * @param {object} [options] - An options object to pass to the logger instance.
     * @param {Console|Logger} [options.logger=console] - The logger object to pass messages to.
     * @param {boolean} [options.enabled=true] - Indicator if this logger object should be enabled
     * and print messages.
     * @param {boolean} [options.formatMessages=true] - Indicator if the logger should create
     * formatted messages.
     */
    constructor(
        moduleName = "Default",
        { logger = console, enabled = true, formatMessages = true } = {}
    ) {
        this.moduleName = moduleName;
        this.enabled = enabled;
        this.logger = logger;
        this._formatMessages = formatMessages;
    }

    /**
     * @param {string} moduleName Name of the module.
     * @param {object} config Update logger configuration.
     * @param {boolean|null} config.enabled - Indicator if this logger object should be enabled
     * and print messages.
     * @returns {Logger} The newly created Logger.
     */
    extend(moduleName, { enabled = this.enabled } = {}) {
        const name = this.methodName ? `${this.moduleName}.${moduleName}` : moduleName;
        return new Logger(name, {
            logger: this.logger,
            enabled: enabled,
            formatMessages: this._formatMessages,
        });
    }

    /**
     * @returns {string} The name of the method or function calling the logger.
     */
    get methodName() {
        let error;

        try {
            throw new Error("");
        } catch (e) {
            error = e;
        }
        // IE9 does not have .stack property
        if (error.stack === undefined) {
            return "";
        }
        let stackTrace = error.stack.split("\n")[6];
        if (/ /.test(stackTrace)) {
            stackTrace = stackTrace.trim().split(" ")[1];
        }
        if (stackTrace && stackTrace.indexOf(".") > -1) {
            stackTrace = stackTrace.split(".")[1];
        }
        return stackTrace;
    }

    /**
     * @param {boolean} [includeMethod=true] - Indicator if the method name should be included with
     * the formatted message.
     * @returns {string} The logging message formatted with the module name and, optionally, the
     * method name.
     */
    getMetaPrefix(includeMethod = true) {
        let method = "";
        if (includeMethod) {
            method = " -> " + this.methodName;
            if (method && (this.methodName.startsWith("_") || this.methodName === "eval")) {
                method = "";
            }
        }
        const name = this.moduleName ?? "";
        const time = `+${Date.now() - START}ms`;
        return `%c[${time}]%c[${name}${method}]`;
    }

    /**
     * @param {string} msg - The log message.
     * @param {boolean} [includeMethod=true] - Indicator if the method name should be included with
     * the formatted message.
     * @param {string} level - Level of the logging message.
     * @returns {Array} The logging message formatted with the module name and, optionally, the
     * method name.
     */
    formatMessage(msg, includeMethod = true, level) {
        if (this._formatMessages) {
            const css = logCss[level] ?? logCss.meta;
            if (!msg) {
                return [this.getMetaPrefix(includeMethod), prefixCss.time, css];
            }
            if (typeof msg !== "object") {
                return [
                    `${this.getMetaPrefix(includeMethod)}%c ${msg}`,
                    prefixCss.time,
                    prefixCss.location,
                    css,
                ];
            }
            return [this.getMetaPrefix(includeMethod), prefixCss.time, css, msg];
        }
        return [msg];
    }

    /**
     * Creates a message in the logger.
     * @param {string} level - The logging level of the message.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message. This creates a log
     * group to bundle everything.
     * @param {string} cssLevelOverride - Level to use when getting CSS for message.
     */
    _sendLog(level, msg, extra, cssLevelOverride = null) {
        const cssLevel = cssLevelOverride ?? level;
        if (this.enabled) {
            if (extra) {
                // this.logger.groupCollapsed(...this.formatMessage(msg, true, level));
                this.logger[level](...this.formatMessage(msg, true, cssLevel), extra);
                // this.logger.groupEnd();
            } else {
                this.logger[level](...this.formatMessage(msg, true, cssLevel));
            }
        }
    }
    /**
     * Create a log message at the META (below debug) level.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message.
     */
    meta(msg, extra = null) {
        this._sendLog("debug", msg, extra, "meta");
    }

    /**
     * Create a log message at the LOG level.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message.
     */
    log(msg, extra = null) {
        this._sendLog("log", msg, extra);
    }

    /**
     * Create a log message at the DEBUG level.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message.
     */
    debug(msg, extra = null) {
        this._sendLog("debug", msg, extra);
    }

    /**
     * Create a log message at the INFO level.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message.
     */
    info(msg, extra = null) {
        this._sendLog("info", msg, extra);
    }

    /**
     * Create a log message at the INFO level with emphasized styling and trace-stack.
     * This should only be used for temporary logging messages while developing.
     * @param {string} msg - The message to log.
     * @param {boolean} includeTrace - Indicator if a trace-stack should be included.
     * @param {object} extra - Extra data to include with the logged message.
     */
    current(msg, includeTrace = false, extra = null) {
        this._sendLog(includeTrace ? "trace" : "info", msg, extra, "current");
    }

    /**
     * Create a log message at the WARN level.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message.
     */
    warn(msg, extra = null) {
        this._sendLog("warn", msg, extra);
    }

    /**
     * Create a log message at the ERROR level.
     * @param {string} msg - The message to log.
     * @param {object} extra - Extra data to include with the logged message.
     */
    error(msg, extra = null) {
        this._sendLog("error", msg, extra);
    }

    /**
     * Logs an object.
     * @param {object} obj - The object to log.
     */
    dir(obj) {
        // eslint-disable-next-line jsdoc/require-jsdoc
        class Log {
            // eslint-disable-next-line jsdoc/require-jsdoc
            constructor(moduleName, args) {
                this._module = moduleName;
                Object.assign(this, args);
            }
        }

        this.logger.dir(new Log(this.moduleName, obj));
    }

    /**
     * Create a collapsable group where logs or other groups can be attached.
     * @param {string} label - The title of the logging group.
     * @param {boolean} [collapsed=true] - Indicator if the logging group should be displayed as
     * already collapsed.
     * @param {string} level - The logging level of the message.
     * @returns {console.group|undefined} - The newly created group.
     */
    startGroup(label = null, collapsed = true, level = "debug") {
        if (this.enabled) {
            const cmd = collapsed ? "groupCollapsed" : "group";
            this.logger[cmd](...this.formatMessage(label, !label, level));
            const newLogger = Object.create(this);
            newLogger._formatMessages = false;
            return newLogger;
        }
        return undefined;
    }

    /**
     * Closes the lowest level open logging group.
     */
    endGroup() {
        if (this.enabled) {
            this.logger.groupEnd();
        }
    }
}

export const globalConsoleLogger = new Logger(null, {
    enabled: process.env.NODE_ENV !== "production",
});

export default {
    /**
     * Log message to enabled providers for LOG level.
     *
     * @param {Vue} Vue A frontend instance to be used with.
     * @param {object} options An object specifying which logging providers to use
     * ex. {useSentry: true, useConsole: true}.
     */
    install(Vue, options) {
        // setup sentry
        if (options.useSentry) {
            const sentry_show_errors = process.env.NODE_ENV !== "production";
            // Errors during component render functions, watchers, lifecycle hooks, and custom event
            // handlers. In 2.6.0+, this hook will capture errors thrown inside v-on DOM listeners.
            // (https://vuejs.org/v2/api/#errorHandler)
            Sentry.init({
                dsn: process.env.SENTRY_DSN,
                environment: process.env.NODE_ENV,
                integrations: (defaults) => [
                    ...defaults.filter(
                        (integration) =>
                            integration.name !== "GlobalHandlers" && integration.name !== "TryCatch"
                    ),
                    new VueIntegration({
                        Vue: Vue,
                        attachProps: true,
                        logErrors: sentry_show_errors,
                    }),
                ],
            });
        }
        const getComponentName = (component) =>
            component?.$vnode?.componentOptions?.Ctor?.extendOptions?.name ||
            component?.$vnode?.componentOptions?.Ctor?.superOptions?.name;
        // Get the Component Name and all of the parent names.
        const getComponentPathName = (component) => {
            let name = getComponentName(component);
            let parent = component?.$parent;
            while (parent?.$vnode) {
                name = `${getComponentName(parent)}.${name}`;
                parent = parent?.$parent;
            }
            return name;
        };
        Vue.prototype.$logger = new Logger();
        Vue.prototype.$logger.enabled = options.useConsole;
        Vue.mixin({
            /**
             *
             */
            beforeCreate() {
                this.$logger = new Logger(getComponentPathName(this), {
                    enabled: options.useConsole,
                });
            },
        });
    },
};
