<!-- This component adds support for any "Add To Cart" links on the BigCommerce Store (other than on the Product page) -->

<template></template>
<script>
import { mapActions } from "vuex";
import { toNumber } from "lodash/lang";

const KNOW_PRODUCT_ID_ATTRIBUTES = ["data-product-id", "data-entity-id"];

export default {
    name: "QuickAddToCart",
    /**
     * Initializing the variables for the application.
     * @returns {object} All the data initialized.
     */
    data() {
        return {
            /**
             * @type {boolean} Indicator if the click handler should run.
             */
            shouldRunHandler: true,
        };
    },
    methods: {
        /**
         * Map the store action for saving a subscription.
         * Update the cartids for elements in subdata - updateSubDataCartId.
         */
        ...mapActions({
            addSubItem: "addSubItem",
            updateSubDataCartIds: "updateSubDataCartIds",
        }),

        /**
         * Whether the "Quick Add" link is clicked. Link is found on products listed on category and/or search pages.
         *
         * @param {Event} event The event object fired on click.
         * @returns {boolean} True if it is a quick add.
         */
        isQuickAddLink(event) {
            const { classList } = event.target ?? {};
            const isQuickAddLink = classList?.contains("rca-cart-quick-link");
            if (isQuickAddLink) this.quickAddElement = event.target;
            return isQuickAddLink;
        },
        /**
         * @param {Event} event The event object fired on click.
         */
        async clickHandler(event) {
            // @ZacharyYoung (04/06/22) A potential concern may be the primary "Add to Cart" button on
            // the individual product page. If we see a bug from this, we would need to add a check below
            // to filter out events for the primary product of the product page.
            if (this.isQuickAddLink(event)) {
                if (this.shouldRunHandler) {
                    this.$logger.debug("Quick add link clicked");
                    // Stop other event handlers
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    // Add the product to Vuex
                    await this.addProduct(event);
                    // Disable this handler
                    this.shouldRunHandler = false;
                    // Click the target again to run the other handlers.
                    event.target.click();
                } else {
                    // Enable this handler
                    this.shouldRunHandler = true;
                    // Wait for page updates and refresh & repair our cart data.
                    await this.$nextTick();
                    await this.bigcommerce.getUpdatedCart();
                }
            }
        },
        /**
         * @param {Event} event The event object fired on click.
         * @returns {number} The BigCommerce Product ID of the event.
         */
        getProductId(event) {
            let productId;
            const target = this.$(event.target);
            // The href is the most reliable way to get the product ID. We use it first.
            const href = target.attrs().href?.toString();
            if (href && href.includes("product_id=")) {
                productId = toNumber(href.split("product_id=")[1]) || null;
            }
            if (!productId) {
                // Find the first value of one of the know product ID attributes.
                productId = KNOW_PRODUCT_ID_ATTRIBUTES.find((attr) => {
                    return target.attr(attr);
                });
            }
            if (!productId) {
                productId = Object.entries(target.attrs).find(([attr, value]) => {
                    // Find the first attribute that returns a number. This *should* be the product ID
                    return toNumber(value) || null;
                });
            }

            // We want a consistent return type, so we return 0 instead of null/undefined.
            return productId || 0;
        },

        /**
         * When the user clicks the add button perform the BC cart add and record a subscription
         * in store if the product is Subscription only or adds a one time purchase.
         * @param {Event} event The event object fired on click.
         */
        async addProduct(event) {
            this.$logger.debug(event, " event");
            const productID = this.getProductId(event);
            const cdnData = this.$store_data.getProductByBCProductID(productID);
            const subscriptionData = cdnData?.subscriptions?.[0];
            try {
                // Set data common between both onetime and subscription products.
                const cartid = this.$store_objects?.cart_id;
                let sub_item = {
                    cartid,
                    productID,
                };
                // For quick add, we only add subscription data if it is subscription only or prepaid.
                if (cdnData.isSubscriptionOnly || cdnData.isPrepaid) {
                    sub_item = {
                        shipping_unit: subscriptionData.order_interval_unit,
                        // Use the first frequency as the default.
                        shipping_frequency: subscriptionData.order_interval_frequency_options[0],
                        charge_frequency: subscriptionData.charge_interval_frequency,
                        discount_type: subscriptionData.discount_type,
                        discount_amount: subscriptionData.discount_amount,
                        storefront_purchase_options: subscriptionData.storefront_purchase_options,
                        cartid,
                        productID,
                    };
                }
                // Our repair cart function should add in the missing cart ID and/or line item ID.
                await this.addSubItem(sub_item);
                this.$logger.debug(`Saved subscription information for productID: ${productID}`);
            } catch (e) {
                this.$logger.error(
                    `Error saving subscription information for cart line: ${productID}, ${e}`
                );
            }
        },
    },
    /**
     * Add a document-level, capturing, click event handler.
     */
    mounted() {
        const self = this;
        document.body.addEventListener("click", self.clickHandler, true);
        this.$logger.debug("Quick Add handler added.");
    },
};
</script>
<style></style>
