<template>
    <div>
        <dom-overwrite
            :target="getCustomClass('grandtotal_label')"
            :dom-props="{ innerHTML: $t('cart.cart_estimated_grand_total_text') + ':' }"
        />
        <dom-overwrite
            :target="getCustomClass('grandtotal_value')"
            :dom-props="{ innerHTML: total }"
        />
    </div>
</template>

<script>
import DomOverwrite from "@/core/vue/components/DomOverwrite";

export default {
    name: "CartGrandTotal",
    components: { DomOverwrite },
    props: {
        total: String,
    },
    methods: {},
};
</script>

<style></style>
