import $ from "jquery-slim";

/**
 * @param {jQuery|string|HTMLElement} selector - Wait for this element to exist.
 * @param {Function} callback - Callback to be run when element is present.
 * @param {Integer} checkFrequencyInMs - Duration for interval.
 * @param {Integer} timeoutInMs - Duration for timeout.
 */
export function waitForElementToExist(
    selector,
    callback,
    checkFrequencyInMs = 20,
    timeoutInMs = 3000
) {
    let checkExist = setInterval(function () {
        let target = doesElementExist(selector);
        if (target) {
            clearInterval(checkExist);
            callback();
        }
    }, checkFrequencyInMs);
    setTimeout(clearInterval, timeoutInMs, checkExist);
}

/**
 * @returns {jQuery|string|HTMLElement} Element that exists in the array of selectors.
 * @param {object[]} selectors - Array of selectors that has the desired element/selector.
 */
export function doesElementExist(selectors) {
    if (Array.isArray(selectors)) {
        return selectors.some((selector) => document.querySelector(selector));
    }
    const element = document.querySelector(selectors);
    return !!element;
}

/**
 * @returns {boolean} Whether element is visible.
 * @param {object[]} selectors - Array of selectors that has the desired element/selector.
 */
export function isElementVisible(selectors) {
    if (Array.isArray(selectors)) {
        return selectors.some((selector) => isElementVisible(selector));
    }
    const element = document.querySelector(selectors);
    return element?.style?.visibility === "visible";
}

/**
 * @param {jQuery|string|HTMLElement} selector - DOM element that needs to become visible.
 * @param {Function} callback - Callback to be run when element is present.
 * @param {Integer} checkFrequencyInMs - Duration for interval.
 * @param {Integer} timeoutInMs - Duration for timeout.
 */
export function waitForElementVisible(
    selector,
    callback,
    checkFrequencyInMs = 20,
    timeoutInMs = 3000
) {
    let checkExist = setInterval(function () {
        const target = isElementVisible(selector);
        if (target) {
            clearInterval(checkExist);
            callback();
        }
    }, checkFrequencyInMs);
    setTimeout(clearInterval, timeoutInMs, checkExist);
}

/**
 * Sleep for X milliseconds.
 * @param {number} ms - The number of milliseconds to sleep.
 * @returns {Promise}
 */
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Wait for the `checker` to return `true` before resolving.
 * @param {Function} checker - The function to check the return of.
 * @param {number} checkFrequencyInMs - The time (in milliseconds) between `checker` calls.
 * @param {number} timeoutInMs - The total time to wait before raising an error.
 * @returns {Promise}
 * @throws WaitForTrue Timeout.
 */
export async function waitForTrue(checker, checkFrequencyInMs = 20, timeoutInMs = 3000) {
    let fail = false;
    let result = !!checker();

    const timeout = setTimeout(() => {
        fail = true;
    }, timeoutInMs);

    while (!result && !fail) {
        await sleep(checkFrequencyInMs);
        result = !!checker();
    }
    if (fail) {
        throw "waitForTrue Timeout";
    }
    clearTimeout(timeout);
}

/**
 * @returns {object[]} Class list.
 * @param {jQuery|string|HTMLElement} selector - DOM element selector.
 * @param {jquery|string|HTMLElement} $elementParent - DOM element parent.
 */
export function listElementStyleClasses(selector, $elementParent = document) {
    // if $elementParent is NULL, it wasn't being replaced
    $elementParent = $elementParent || document;
    const $element = $elementParent.querySelector(selector) || {};
    const cssClasses = Array.from($element.classList);
    const classesToIgnore = /active|hide|visible/;
    // remove visibility/status modifiers
    return cssClasses.filter((cssClass) => !classesToIgnore.test(cssClass));
}

/**
 * @returns {jQuery|HTML|Element} With value of an html entity.
 * @param {object[]} value - Is an array of html entities.
 */
export function decodeHtmlEntity(value) {
    const valueType = typeof value;
    if (Array.isArray(valueType)) {
        value.map((item) => decodeHtmlEntity(item));
        return value;
    } else if (valueType === "object" && valueType !== null) {
        const newObj = {};
        for (const [key, val] of Object.entries(value)) {
            newObj[key] = decodeHtmlEntity(val);
        }
        return newObj;
    }
    return $("<textarea/>").html(value).text();
}
