import { isEqual, isEqualWith, merge, omit } from "lodash";

/**
 * Compare two objects to see if they are the same object.
 * @param {object} source - The object to compare.
 * @param {object} target - The other object to compare.
 * @param {object} options - Options.
 * @param {Array} [options.exclude=[]] - List of keys to exclude from comparison.
 * @param {boolean} [options.logErrors=false] - If true, mismatched keys will be printed to the console.
 * @returns {boolean}
 */
export function objectsAreSame(source, target, { exclude = [], logErrors = false } = {}) {
    const _s = omit(source, exclude);
    const _t = omit(target, exclude);
    return isEqualWith(_s, _t, (objValue, othValue, key) => {
        const result = isEqual(objValue, othValue);
        if (key !== undefined) {
            if (!result && logErrors) {
                console.debug(`${key}: ${source[key]} !== ${target[key]}`);
            }
            return result;
        }
        return undefined;
    });
}

/**
 * Compare two arrays of objects to see if they are the same arrays.
 * @param {Array} list1 - The array to compare.
 * @param {Array} list2 - The other array to compare.
 * @param {Array | null} exclude - A list of object keys to exclude from comparison.
 * @returns {boolean}
 */
export function compareArrayOfObjects(list1, list2, exclude = null) {
    const _map = (v) => omit(merge({}, v), exclude);
    if (list1.length && list1.length === list2.length) {
        return isEqual(list1.map(_map), list2.map(_map));
    }
    return list1.length === list2.length;
}

const nodeKeysToIgnore = ["isConnected", "parentNode", "parentElement", "childNodes"];

/**
 * Compare two Nodes to see if they are the same Node.
 * @param {Node} node1 - The Node to compare.
 * @param {Node} node2 - The other Node to compare.
 * @returns {boolean}
 */
export function compareNodes(node1, node2) {
    return objectsAreSame(node1, node2, { exclude: nodeKeysToIgnore, logErrors: true });
}

/**
 * Compare two arrays of Nodes to see if they are the same arrays.
 * @param {NodeList} list1 - The Array of Nodes to compare.
 * @param {NodeList} list2 - The other Arrays of Nodes to compare.
 * @returns {boolean}
 */
export function compareNodesList(list1, list2) {
    return compareArrayOfObjects([...list1.values()], [...list2.values()], nodeKeysToIgnore);
}

/**
 * Take in a unix timestamp and convert it to iso format.
 * @param {string} timestamp - A unix timestamp.
 * @returns {string} ISO date format.
 */
export function unixToISOTimestamp(timestamp) {
    const unixTime = new Date(timestamp * 1000),
        month = ("0" + (unixTime.getMonth() + 1)).slice(-2), //for double digits in month
        day = ("0" + unixTime.getDate()).slice(-2), //for double digits in days
        year = unixTime.getFullYear();
    return `${year}-${month}-${day}`;
}
