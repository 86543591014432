<template>
    <span v-if="ready" :key="key">
        <slot></slot>
    </span>
</template>

<script>
import { compareNodesList } from "@/core/utils/comparison_and_validate";
import { jqueryObserver } from "@/core/utils/mutation-observer-helper";
import { Watcher } from "@/core/utils/watcher";
import jQuery from "jquery-slim";

const MAX_NODES_TO_COMPARE = 20;

/**
 * @description The WaitFor Vue component. This component searches for an element via jQuery by using
 * the `target` selector. Once the target is found in the DOM and other criteria are met, the
 * slotted component is then mounted.
 */
export default {
    name: "WaitFor",
    /**
     * @type {object}
     * @property {jQuery|string|HTMLElement} target The target jQuery/CSS selector to wait for.
     * @property {boolean} [once] Only allow the slotted component to mount a single time.
     * @property {Function} [onFound] A callback function to call when the target is found in the DOM.
     * @property {number} [setFor=0] The number of milliseconds the `target` needs to have no Nodes added/removed before the slot is mounted.
     */
    props: {
        target: {
            type: [jQuery, String, HTMLElement],
            required: true,
        },
        once: Boolean,
        onFound: Function,
        setFor: {
            type: Number,
            default: 0,
        },
    },
    /**
     * @returns {object}
     * @property {boolean} targetFound Indicator if the targeted element has been found on the page.
     * @property {number|null} lastChangeTime Unix time of when the `target` element experienced a Node change.
     * @property {boolean} isTargetSetEventEmitted Indicator if the "target-set" event has been emitted.
     * @property {object} observers Namespace for MutationObservers.
     * @property {object} watchers Namespace for Watchers.
     * @property {object} emitEvent Namespace for events that are emitted in the module.
     */
    data() {
        const app = this;
        return {
            targetFound: false,
            lastChangeTime: null,
            isTargetSetEventEmitted: false,
            key: 0,
            observers: {
                /**
                 * Observes the "html" element for new Nodes being added. When a Node is added that
                 * matches the `target` selector, `targetFoundHandler` is called.
                 */
                findTarget: new jqueryObserver("html", app.targetFoundHandler, {
                    options: "childList subtree",
                    filter: app.target,
                    logger: app.$logger.extend("findTarget"),
                }),
                /**
                 * Observes the `target` element for Nodes changing. Every time a Node changes,
                 * `updateChangeTime` is called. The "targetSet" watcher is started when the
                 * observer is started.
                 */
                checkTargetSet: new jqueryObserver(app.target, app.updateChangeTime, {
                    options: "childList subtree",
                    onStart: () => app.watchers.targetSet.start,
                    logger: app.$logger.extend("checkTargetSet Observer"),
                }),
            },
            watchers: {
                /**
                 * Watches the `isTargetSet` method. When it is "True", the watcher turns off.
                 */
                targetSet: new Watcher(
                    () => app.isTargetSet(),
                    () => app.watchers.targetSet.disable(),
                    {
                        logger: app.$logger.extend("TargetSet Watcher"),
                        checker: (oldResult, newResult) => newResult === true,
                        once: true,
                    }
                ),
            },
        };
    },
    computed: {
        /**
         * @returns {boolean} Indicator if "target-found" event should be emitted.
         */
        shouldEmitTargetFound() {
            return (this.once && !this.targetFound) || !this.once;
        },
        /**
         * @returns {boolean} Indicator is slot is ready to mount.
         */
        ready() {
            const result = this.targetFound && this.isTargetSet();
            if (result) {
                this.$logger.debug("Mounting slot");
            }
            return result;
        },
    },
    methods: {
        /**
         * Updates `lastChangeTime` to current time.
         */
        updateChangeTime() {
            this.lastChangeTime = Date.now();
        },
        /**
         * @fires target-set
         * @returns {boolean} Indicator is `target` element is "set".
         */
        isTargetSet() {
            if (!this.isTargetSetEventEmitted) {
                const timeFromLastChange = Date.now() - (this.lastChangeTime || Date.now());
                const result = timeFromLastChange > this.setFor;
                if (result) {
                    this.$logger.meta(`${timeFromLastChange}ms > ${this.setFor}ms = ${result}`);
                    this.targetSetEventEmitter();
                }
                return result;
            }
            return true;
        },
        /**
         * @param {MutationRecord} mutationRecord The MutationObserver Record to check.
         * @returns {boolean} Indicator if a change was actually made during an observed mutation.
         * If the nodes added and removed in a record are the same, no change is considered made.
         */
        isChangeMade(mutationRecord) {
            return !compareNodesList(mutationRecord.addedNodes, mutationRecord.removedNodes);
        },
        /**
         * The handler for the mutation observer.
         * @param {MutationRecord} record The MutationObserver record.
         */
        targetFoundHandler(record) {
            // If the nodes added and removed are exactly the same, we do nothing.
            // This prevents circular logic from the target being updated via jQuery.
            // If the number of Nodes added is above 20, we assume a change was made.
            if (record.addedNodes.length > MAX_NODES_TO_COMPARE || this.isChangeMade(record)) {
                this.runTargetFoundUpdates();
            }
        },
        /**
         * Updates and functions to run when the `target` is found.
         */
        runTargetFoundUpdates() {
            this.$logger.meta(this.target);
            this.updateChangeTime();
            if (this.shouldEmitTargetFound) {
                this.setTargetFound();
            }
            // Disable the observer if the once setting is True.
            // It cannot be started again until it is no longer disabled.
            if (this.once) {
                this.observers.findTarget.disable();
            }
            // Now that the target has been found, we can begin checking for its "set" status.
            if (this.setFor) {
                this.observers.checkTargetSet.start();
            }
        },
        /**
         * Sets the target as found and fires the `target-found` event.
         * @fires target-found
         */
        setTargetFound() {
            this.key = Date.now();
            this.targetFoundEventEmitter();
            if (this.setFor) {
                this.watchers.targetSet.start();
            }
        },
        /**
         * Pause the MutationObserver and run a callback function. This is useful when calling a
         * function that would cause the observer to trigger an additional time.
         * @param {Function} func The function to call while the observer is paused.
         */
        withPaused(func) {
            this.observers.findTarget.whilePaused(func);
        },
        /**
         * @event target-found Fired when the target is found. Emits the `target` with it.
         * @fires target-found
         */
        targetFoundEventEmitter() {
            this.$logger.debug(`Emit "target-found" for ${this.target}`);
            this.$emit("target-found", this.target);
            this.targetFound = true;
        },
        /**
         * @event target-set Fired after the target is found and has been "set". The element is considered
         * "set" when its subtree has not had any Nodes added for `setFor` milliseconds. Emits the `target`
         * with it.
         * @fires target-set
         */
        targetSetEventEmitter() {
            this.$logger.debug(`Emit "target-set" for ${this.target}`);
            this.$emit("target-set", this.target);
            this.isTargetSetEventEmitted = true;
            this.observers.checkTargetSet.disable();
        },
    },

    /**
     * Searches for the target in the DOM. If it is found, trigger 'runTargetFoundUpdates`. Starts the MutationObserver.
     */
    mounted() {
        this.$logger.debug(`Target: ${this.target}`);
        if (this.observers.findTarget.isTargetFound) {
            this.runTargetFoundUpdates();
        }
        this.observers.findTarget.start();
    },
    /**
     * Stops the MutationObserver when the component is destroyed.
     */
    beforeDestroy() {
        this.observers.findTarget.stop();
    },
};
</script>

<style></style>
