<!-- This component copies an existing elements/node in the DOM and converts it into a Vue
 component. Does not overwrite the cloned element.-->
<template>
    <div>
        <component v-if="template" ref="self" :is="template"></component>
        <wait-for
            :once="true"
            :target="target"
            @target-found="handleTargetFoundListener"
        ></wait-for>
    </div>
</template>

<script>
import { nanoid } from "nanoid";
import WaitFor from "@/core/vue/components/WaitFor";
import jQuery from "jquery-slim";

export default {
    name: "Clone",
    components: {
        WaitFor,
    },
    props: {
        // Element to clone
        target: {
            type: [jQuery, String, HTMLElement],
            required: true,
        },
        // Add or replace the cloned element `id` with this value.
        id: {
            type: String,
            default: null,
        },
        // Array of classes to add to the new cloned element.
        addClasses: {
            type: Array,
            default: null,
        },
    },
    // eslint-disable-next-line jsdoc/require-returns
    /**
     * @property {string} rca_id - The unique ID for the temporary element created.
     * @property {object} targetElement - The target as a jQuery object.
     */
    data() {
        const rca_id = nanoid();
        return {
            rca_id,
            targetElement: {},
        };
    },
    computed: {
        /**
         * @returns {object} The template object containing the HTML for the cloned element.
         */
        template() {
            const app = this;
            if (this.targetElement.length) {
                this.targetElement.attr("rca_id", this.rca_id);
                if (this.id) {
                    this.targetElement.attr("id", this.id);
                }
                if (this.addClasses) {
                    this.targetElement.addClass(this.addClasses);
                }
                return {
                    template: app.targetElement.prop("outerHTML"),
                    /**
                     *
                     */
                    mounted() {
                        for (const event in app.$listeners) {
                            app.$el.addEventListener(event, app.$listeners[event]);
                        }
                    },
                };
            }
            return null;
        },
    },
    methods: {
        /**
         * Assign jQuery representation of target to targetElement.
         * @param {string} target The emitted target string from target-found event.
         */
        handleTargetFoundListener(target) {
            this.targetElement = this.$(document).find(target);
            this.$logger.debug("Clone target found.");
        },
    },
};
</script>

<style></style>
