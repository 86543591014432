<template>
    <span>
        <dom-overwrite
            :target="getCustomClass('header_subtotal_label')"
            :dom-props="{ innerHTML: innerHTML.header }"
            @found="eventHandlers.headerFound"
        />
        <dom-overwrite
            :target="getCustomClass('subtotal_label') + ':last'"
            :dom-props="{ innerHTML: innerHTML.label }"
            @found="eventHandlers.labelFound"
        />
        <dom-overwrite
            :target="getCustomClass('subtotal_value') + ':last'"
            :dom-props="{ innerHTML: subtotalText }"
        />
        <dom-overwrite :target="getCustomClass('subtotal')" hide />
    </span>
</template>

<script>
import DomOverwrite from "@/core/vue/components/DomOverwrite";
import { debounce } from "lodash";

export default {
    name: "CartSubtotal",
    components: { DomOverwrite },
    /**
     * @property {string} subtotalText The currency text string for the value of the subtotal.
     */
    props: {
        subtotalText: String,
    },
    /**
     * @returns {object} Includes prefix and innerHTML.
     * @property {string} prefix - The string to prefix to the subtotal labels.
     * @property {object} innerHTML The innerHTML strings for subtotal elements.
     * @property {string} innerHTML.header The innerHTML string for the subtotal header.
     * @property {string} innerHTML.label The innerHTML string for the subtotal label.
     */
    data() {
        return {
            prefix: this.$t("cart.cart_subtotal_prefix"),
            innerHTML: {
                header: undefined,
                label: undefined,
            },
        };
    },
    computed: {
        /**
         * @returns {object} Event Handlers.
         * @property {Function} headerFound A debounced function to update the header innerHTML.
         * @property {Function} labelFound A debounced function to update the label innerHTML.
         */
        eventHandlers() {
            const headerFound = (el) => this.updateInnerHTML(el, "header");
            const labelFound = (el) => this.updateInnerHTML(el, "label");
            return {
                headerFound: debounce(headerFound, 100, { leading: true, trailing: false }),
                labelFound: debounce(labelFound, 100, { leading: true, trailing: false }),
            };
        },
    },
    methods: {
        /**
         * Adds the prefix to the target element's innerHTML if it is not already present.
         * @param {jQuery} el The jQuery DOM element to read the innerHTML of.
         * @param {'header'|'label'} key The subtotal element to update.
         */
        updateInnerHTML(el, key) {
            const current = el.prop("innerHTML");
            if (!current.includes(this.prefix)) {
                this.innerHTML[key] = `${this.prefix} ${current}`;
            }
        },
    },
};
</script>

<style></style>
