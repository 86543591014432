<template>
    <span :key="mountKey" v-if="rechargeCartSummary && shouldCartPageRun">
        <!-- Cart Total-->
        <cart-grand-total ref="cart_grand_total" :total="rechargeCartTotals.grand" />
        <!-- Cart Subtotal -->
        <cart-subtotal ref="cart_subtotal" :subtotalText="rechargeCartTotals.sub" />
        <!-- Cart Line Items -->
        <template v-for="item in rechargeCartSummary.items">
            <cart-line-item ref="cart_line_items" :key="item.id" :item="item" />
        </template>
        <!-- Cart Discounts -->
        <dom-overwrite
            :target="getCustomClass('cart_discount')"
            :hide="rechargeCartSummary.totalBigcommerceDiscount === 0"
        />
        <!-- Elements to Hide -->
        <dom-overwrite :target="getCustomClass('cart_hide_subscription')" hide />
        <dom-overwrite :target="getCustomClass('cart_coupon')" hide />
        <dom-overwrite :target="getCustomClass('taxes')" hide />
        <dom-overwrite :target="getCustomClass('shipping_label')" hide />
    </span>
</template>

<script>
import { CurrencyMixin } from "@/core/vue/mixins";
import CartGrandTotal from "@/bigcommerce/pages/cart/CartGrandTotal";
import CartSubtotal from "@/bigcommerce/pages/cart/CartSubtotal";
import CartLineItem from "@/bigcommerce/pages/cart/CartLineItem";
import { nanoid } from "nanoid";
import { jqueryObserver } from "@/core/utils";
import CartSummary from "@/bigcommerce/pages/cart/cartSummary";
import DomOverwrite from "@/core/vue/components/DomOverwrite";
import { debounce, throttle } from "lodash";

// Use `this.bigcommerce.storefront.cart` for the BigcommerceCart class helper.
// Remember that the cart sometimes needs to be refreshed via
// this.bigcommerce.storefront.cart.getCheckoutData()

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Cart",
    /**
     * List of objects to build UI components for line-items.
     * @returns {object}
     */
    data() {
        return {
            /**
             * A collection of subscription line items.
             */
            subscriptionLineItems: [],
            /**
             * Data from RCA_store_objects.cart.
             */
            storeObjectsCart: null,

            /**
             * Unique value assigned when the component is mounted.
             */
            mountKey: nanoid(),

            /**
             * Holds the cart items.
             */
            rechargeCartSummary: null,
            /**
             * The previous cart summary.
             */
            previousRechargeCartSummary: null,

            /**
             * Rate limits set for both debounce and throttle.
             */
            rateLimit: {
                logger: debounce((level, msg, data) => this.$logger[level](msg, data), 50),
                remountCart: debounce(this.remountCart, 50, { leading: true }),
            },

            /**
             * Get rca class equivalents for this classes.
             */
            requiredCustomClasses: [
                this.getCustomClass("grandtotal_value"),
                this.getCustomClass("cart_line_item"),
            ],
            /** Cart page status indicators. */
            status: {
                /**
                 * @type {boolean} If true, the Cart Page has already run start logic.
                 */
                started: false,
            },
        };
    },
    mixins: [CurrencyMixin],
    components: {
        DomOverwrite,
        CartLineItem,
        CartSubtotal,
        CartGrandTotal,
    },
    computed: {
        /**
         * Once all calls are completed debounce will emit the mutated event.
         * @returns {object}
         */
        events() {
            const app = this;
            return {
                mutated: throttle((record) => app.$emit("mutated", record), 20),
            };
        },
        /**
         * @returns {object} Cart object with observer.
         */
        observers() {
            const app = this;
            return {
                cart: new jqueryObserver("html", (record) => app.events.mutated(record), {
                    options: "added subtree",
                    filter: ":not([dom-overwrite])",
                    logger: this.$logger.extend("CartObserver"),
                }),
            };
        },
        /**
         * @returns {boolean} Indicator is the Cart Page is enabled in the adapter settings.
         */
        isEnabledInSettings() {
            const isEnabled = this.settings?.pages?.cart?.enabled || true;
            this.$logger.debug(isEnabled);
            return isEnabled;
        },
        /**
         * @returns {boolean} True if allCheckoutsonRecharge are true or the current cart is a subscription cart.
         */
        isRechargeCart() {
            const result = !!(
                this.settings.allCheckoutsOnRecharge || this.rechargeCartSummary?.isSubscriptionCart
            );
            this.$logger.debug(result);
            return result;
        },
        /**
         * @returns {boolean} Indicator if the Cart page should run for this store and cart.
         */
        shouldCartPageRun() {
            return this.isEnabledInSettings && this.isRechargeCart;
        },
        /**
         * @returns {object} RechargeCartTotals assembles the grandtotal and subtotal for the current cart.
         */
        rechargeCartTotals() {
            const summary = this.rechargeCartSummary;
            return {
                grand: summary?.totalPrice?.format?.(),
                sub: summary?.subtotalPrice?.format?.(),
            };
        },
        /**
         * @returns {boolean} Indicator if the ReCharge Cart has changed since it was last pulled.
         */
        isCartSummaryChanged() {
            return this.previousRechargeCartSummary !== JSON.stringify(this.rechargeCartSummary);
        },
    },
    methods: {
        /**
         * Add custom classes to DOM elements.
         */
        addCustomClasses: function () {
            this.addCustomClassesToGroup("ALL");
            this.addCustomClassesToGroup("CART", {
                discountText: this.$store_objects?.cart?.discount?.formatted,
                subtotalText: this.$store_objects?.cart?.sub_total?.formatted,
            });
        },
        /**
         * Remount the cart data if the record has changes.
         * @param {object} record Line item.
         */
        async remountCart(record) {
            const isAddedByApp = !!this.$(record?.addedNodes)?.attr?.("dom-overwrite");
            if (!isAddedByApp) {
                const areRequiredClassesPresent = this.requiredCustomClasses.every(
                    (_class) => !!this.$(_class).length
                );
                if (!areRequiredClassesPresent) {
                    this.$logger.debug("remountCart | record", record);
                    // Get new cart data from the storefront API
                    await this.refreshBigcommerceCart();
                    this.startCartPage();
                }
            }
        },
        /**
         * Creates a ReCharge Cart Summary object.
         */
        getCartSummary() {
            this.previousRechargeCartSummary = JSON.stringify(this.rechargeCartSummary);
            const cart = this.bigcommerce.storefront.cart;
            // Get cart data from store objects.
            /** @type {cartData} */
            let formattedCart = {
                subTotal: this.$store_objects.cart.sub_total.formatted,
                grandTotal: this.$store_objects.cart.grand_total.formatted,
                taxes: this.$store_objects.cart.taxes.map((i) => i.cost.formatted),
                discount: this.$store_objects.cart?.discount?.value,
                items: this.$store_objects.cart.items.map((item) => ({
                    id: item.id,
                    quantity: item.quantity,
                    price: item.price.formatted,
                })),
            };
            // If there is checkout data from the storefront API, use it.
            if (cart.checkoutData) {
                /** @type {cartData} */
                formattedCart = {
                    subTotal: cart.checkoutData.subtotal,
                    grandTotal: cart.checkoutData.grandTotal,
                    taxes: cart.checkoutData.taxes.map((i) => i.amount),
                    discount: cart.cartData.discountAmount,
                    items: cart.allLineItems.map((item) => ({
                        id: item.id,
                        quantity: item.quantity,
                        price: item.salePrice,
                    })),
                };
            }

            // Zero out discount value if discounts are disabled in the settings.
            if (!this.settings.backend.use_bc_discounts) {
                formattedCart.discount = 0;
            }

            this.rechargeCartSummary = new CartSummary({
                cartData: formattedCart,
                subscriptionData: this.$store.getters.currentSubData,
                currency: this.Currency,
            });
            // This can be a noisy logger, so we are debouncing it.
            this.rateLimit.logger("debug", "getCartSummary | Data", {
                rawCart: this.$store_objects.cart,
                formattedCart,
                summary: this.rechargeCartSummary,
            });
        },
        /**
         * If the Cart Page is not started yet, this adds custom classes and starts observers.
         * If the Cart Page has already started, this adds custom classes and remounts the page.
         */
        startCartPage() {
            if (!this.status.started) {
                this.status.started = true;
                this.$logger.debug("Cart page running in ReCharge Checkout Mode.");
                this.addCustomClasses();
                this.observers.cart.start();
            } else {
                this.addCustomClasses();
                // This creates a new key value which forces Vue to remount keyed components.
                this.mountKey = nanoid();
            }
        },
        /**
         * Gets new BigCommerce Cart data and re-creates the ReCharge Cart Summary.
         */
        async refreshBigcommerceCart() {
            await this.bigcommerce.getUpdatedCart();
            this.getCartSummary();
        },
    },
    /**
     *
     */
    created() {
        // Use a throttled remountCart to prevent pages which spam changes from triggering too
        // many calls to remount.
        this.$on("mutated", this.rateLimit.remountCart);
    },
    /**
     *
     */
    async mounted() {
        this.$logger.info("Cart Page Mounted");
        this.getCartSummary();
        if (this.shouldCartPageRun) {
            this.startCartPage();
        }
        await this.refreshBigcommerceCart();
        if (this.isCartSummaryChanged && this.shouldCartPageRun) {
            this.startCartPage();
        }
    },
    /**
     *
     */
    destroyed() {
        this.observers.cart.stop();
    },
};
</script>
<style></style>
