var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"rca-subscription-form__buttons-type"},[_c('label',{staticClass:"rca-subscription-form__buttons"},[(_vm.showSubscription)?_c('label',{staticClass:"rca-subscription-form__button rca-button-left",class:[
                {
                    'rca-subscription-form__button--selected':
                        _vm.value === _vm.optionProps.first.inputValue,
                },
                _vm.optionProps.first.inputClass ],on:{"click":function($event){return _vm.$emit('input', _vm.optionProps.first.inputValue)}}},[_c('span',{class:_vm.optionProps.first.textClass},[_vm._v(_vm._s(_vm.optionProps.first.spanText))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"rca-subscription-form__button-price"},[_vm._v(_vm._s(_vm.optionProps.first.spanPrice))])]):_vm._e(),_vm._v(" "),(_vm.showSubscription)?_c('label',{staticClass:"rca-subscription-form__button rca-button-right",class:[
                {
                    'rca-subscription-form__button--selected':
                        _vm.value === _vm.optionProps.second.inputValue,
                },
                _vm.optionProps.second.inputClass ],on:{"click":function($event){return _vm.$emit('input', _vm.optionProps.second.inputValue)}}},[_c('span',{class:_vm.optionProps.second.textClass},[_vm._v(_vm._s(_vm.optionProps.second.spanText))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"rca-subscription-form__button-price"},[_vm._v(_vm._s(_vm.optionProps.second.spanPrice))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }