/**
 * @file A Vue plugin that loads a store's data from the RCA_DATA variable.
 */

/**
 *
 * @typedef {object} RcaData The RCA_DATA variable. Contains store data from the adapter CDN file.
 *
 * @property {Array<RcaDataProduct>} RCA_PRODUCT_DATA Adapter product data.
 * @property {RCA_ADAPTER_SETTINGS_DATA} RCA_ADAPTER_SETTINGS_DATA Raw settings data from the backend.
 * @property {RCA_FILE_DATA} RCA_FILE_DATA Meta-data about the CDN file data.
 * @property {RCA_STORE_DATA} RCA_STORE_DATA Data about the current store.
 */

/**
 * @typedef {object} RCA_FILE_DATA Meta-data about the CDN file data.
 *
 * @property {number} updated_at The Unix time this CDN file data was last updated.
 * @property {number} version The version of the CDN file data.
 */

/**
 * @typedef {object} RCA_STORE_DATA Data about the current store.
 *
 * @property {string} hash The BigCommerce Store Hash for the current store.
 * @property {string} rc_domain The ReCharge Store Domain for the current store.
 * @property {string} theme The BigCommerce theme for the current store.
 * @property {string} weight_units The weight unit used for the BigCommerce Product Weight.
 */

/**
 * @todo Remove the discounts field. It is not used anywhere within the codebase.
 * @todo Convert subscriptions fom a single item array to an object.
 *
 * @typedef {object} RcaDataProduct
 *
 * @property {Array<object>} discounts Deprecated.
 * @property {number} id The BigCommerce product ID.
 * @property {Array<RcaDataProductSubscription>} subscriptions A single item array containing ReCharge subscription data for the product.
 * @property {string} tax_code The BigCommerce tax code of the product.
 * @property {Array<{id: number, tax_class_id: string, tax_code: string, weight: number}>} variants Array of product variants.
 * @property {number} weight The weight of the product in the store's native units. (ie This is not converted to grams).
 * @property {boolean|undefined} isSubscription Indicator if this is a subscription product.
 * @property {boolean|undefined} isSubscriptionOnly Indicator if this is a subscription-only product.
 * @property {boolean|undefined} isPrepaid Indicator if this is a prepaid subscription product.
 */

/**
 * @typedef {object} RcaDataProductSubscription
 *
 * @property {number} charge_interval_frequency How often the customer is charged for this subscription.
 * @property {'percentage'|'fixed'} discount_type The type of the Subscribe-and-Save discount. Always percentage.
 * @property {number} discount_amount The amount of the Subscribe-and-Save discount.
 * @property {number} expire_after_specific_number_of_charges Deprecated.
 * @property {Array<string|number>} order_interval_frequency_options Array of the available shipping frequency values for the subscription.
 * @property {string} order_interval_unit The unit (eg day, week, etc.) to use when calculating shipping frequency.
 * @property {"subscription_only"|"subscription_and_onetime"|"inactive"} storefront_purchase_options The type of subscription.
 */

/**
 * @typedef {object} RCA_ADAPTER_SETTINGS_DATA Raw settings data from the backend.
 *
 * @property {string} store_hash The BigCommerce Store Hash for the current store.
 * @property {boolean} sync_orders Indicator if ReCharge orders are injected into BigCommerce.
 * @property {boolean} cancel_store_order Boolean value settings telling process whether to cancel an order on BC or not.
 * @property {boolean} is_rc_staging Boolean value settings on whether to use the ReCharge Staging API or not.
 * @property {?string} rc_api_url String value that sets a custom recharge API URL.
 * @property {boolean} sync_rc_refund_order_status_to_platform Indicator is refunded ReCharge orders sync to BigCommerce.
 * @property {string} created_at UTC datetime the settings were created.
 * @property {string} updated_at UTC datetime the settings last updated.
 * @property {boolean} all_checkouts_on_recharge Indicator if all BigCommerce checkouts should go to ReCharge.
 * @property {boolean} display_and_handoff_coupons Indicator if BigCommerce coupons should be displayed on the Frontend and handed-off to ReCharge checkout.
 * @property {boolean} use_bc_discounts Indicator if BigCommerce automatic discounts should be displayed on the Frontend and handed-off to ReCharge checkout. This overrides "display_and_handoff_coupons".
 * @property {boolean} stack_subandsave Indicator is Subscribe-and-Save discounts should be used on top of BigCommerce discounts.
 * @property {boolean} apply_bc_discount_to_first_order Indicator if BigCommerce discounts should only be applied to the first order.
 * @property {boolean} create_coupon_for_coupon_only_cart Indicator if BigCommerce coupon codes should be handed-off to ReCharge without modification.
 * @property {string} end_to_end_test_type Represents if and how the end-to-end tests should be run for this store.
 * @property {Array<string>} end_to_end_skip_tests List with the end-to-end tests that are supposed to be skipped.
 * @property {number} inject_orders_hours_ago_start The start of the time interval that is used to fetch the missing orders for injection.
 * @property {number} inject_orders_hours_ago_end The end of the time interval that is used to fetch the missing orders for injection.
 * @property {number} refund_orders_days_ago_start The start of the time interval that is used to fetch the missing orders for refund.
 * @property {number} refund_orders_days_ago_end The end of the time interval that is used to fetch the missing orders for refund.
 * @property {boolean} prefill_checkout_form Indicator if the ReCharge checkout form should be prefilled with customer data.
 */

/**
 * @class RcaDataScript
 * @description A class to interact with the data from the adapter CDN file. Mirrors the "ScriptStorage" class from the CDN file but with enhancements.
 */
class RcaDataScript {
    /**
     * @param {RcaData} RCA_DATA The RCA_DATA variable from the CDN file.
     */
    constructor(RCA_DATA) {
        /**
         * Meta-data about the CDN file data.
         * @type {RCA_FILE_DATA}
         */
        this.RCA_FILE_DATA = RCA_DATA.RCA_FILE_DATA;
        /**
         * Data about the current store.
         * @type {RCA_STORE_DATA}
         */
        this.RCA_STORE_DATA = RCA_DATA.RCA_STORE_DATA;
        /**
         * Adapter product data.
         * @type {Array<RcaDataProduct>}
         */
        this.RCA_PRODUCT_DATA = RCA_DATA?.RCA_PRODUCT_DATA?.map((product) => {
            const subscriptionData = product?.subscriptions?.[0];
            const subscriptionType = subscriptionData?.storefront_purchase_options;
            // Determine if this is a subscription product
            product.isSubscription =
                subscriptionData &&
                ["subscription_only", "subscription_and_onetime"].includes(subscriptionType);
            if (product.isSubscription) {
                // Convert frequencies into numbers.
                const frequencies = subscriptionData.order_interval_frequency_options.map((f) =>
                    parseInt(f, 10)
                );
                product.subscriptions[0].order_interval_frequency_options = frequencies;
                // Add isSubscriptionOnly / isPrepaid indicators
                product.isSubscriptionOnly = subscriptionType === "subscription_only";
                product.isPrepaid =
                    product.isSubscriptionOnly &&
                    frequencies.length === 1 &&
                    subscriptionData?.charge_interval_frequency &&
                    subscriptionData?.charge_interval_frequency > frequencies[0] &&
                    subscriptionData?.charge_interval_frequency % frequencies[0] === 0;
            }
            return product;
        });
        /**
         * Raw settings data from the backend.
         * @type {RCA_ADAPTER_SETTINGS_DATA}
         */
        this.RCA_ADAPTER_SETTINGS_DATA = RCA_DATA.RCA_ADAPTER_SETTINGS_DATA;
        /**
         * Array of subscription products for the store.
         * @type {RcaDataProduct[]}
         */
        this.subscriptionProducts = RCA_DATA?.RCA_PRODUCT_DATA?.filter(
            (product) => product.isSubscription
        );
    }
    /**
     *
     */
    set subscriptionProducts(productList) {
        Object.defineProperty(this, "subscriptionProducts", productList);
    }
    /**
     * Lazy-loaded.
     * @returns {RcaDataProduct[]} Array of all subscription products.
     */
    get subscriptionProducts() {
        const value = this.RCA_PRODUCT_DATA.filter((product) => product.isSubscription);
        Object.defineProperty(this, "subscriptionProducts", { value });
        return value;
    }
    /**
     * Lazy-loaded.
     * @returns {RcaDataProduct[]} Array of all subscription-only products.
     */
    get subscriptionOnlyProducts() {
        const value = this.RCA_PRODUCT_DATA.filter((product) => product.isSubscriptionOnly);
        Object.defineProperty(this, "subscriptionOnlyProducts", { value });
        return value;
    }
    /**
     * Lazy-loaded.
     * @returns {RcaDataProduct[]} Array of all prepaid products.
     */
    get prepaidProducts() {
        const value = this.RCA_PRODUCT_DATA.filter((product) => product.isPrepaid);
        Object.defineProperty(this, "prepaidProducts", { value });
        return value;
    }
    /**
     * @returns {RCA_ADAPTER_SETTINGS_DATA} The raw Adapter Backend Settings for the current store.
     */
    get adapterSettings() {
        return this.RCA_ADAPTER_SETTINGS_DATA;
    }
    /**
     * @returns {string} The ReCharge Store Domain of the current store.
     */
    get storeDomain() {
        return this.RCA_STORE_DATA.rc_domain;
    }

    /**
     * @returns {string} The BigCommerce Store hash of the current store.
     */
    get storeHash() {
        return this.RCA_STORE_DATA.hash;
    }

    /**
     * @deprecated Use `adapterSettings`.
     * @returns {RCA_ADAPTER_SETTINGS_DATA} The raw Adapter Backend Settings for the current store.
     */
    getAdapterSettings() {
        return this.adapterSettings;
    }

    /**
     * @deprecated Use `storeDomain`.
     * @returns {string} The ReCharge Store Domain of the current store.
     */
    getStoreDomain() {
        return this.RCA_STORE_DATA.rc_domain;
    }

    /**
     * @deprecated Use `storeHash`.
     * @returns {string} The BigCommerce Store hash of the current store.
     */
    getStoreHash() {
        return this.RCA_STORE_DATA.hash;
    }

    /**
     * @param {number} product_id The BigCommerce Product ID to search with.
     * @returns {?RcaDataProduct} The Adapter CDN data matching the BigCommerce Product ID.
     */
    getProductByBCProductID(product_id) {
        return this.RCA_PRODUCT_DATA.find((p) => Number(p.id) === product_id);
    }

    /**
     * @param {number} product_id The BigCommerce Product ID to search with.
     * @returns {?Array<RcaDataProductSubscription>} The Adapter CDN subscription data matching the BigCommerce Product ID.
     */
    getSubscriptionsByBCProductID(product_id) {
        return this.getProductByBCProductID(product_id)?.subscriptions ?? [];
    }
}

/**
 * A single instance of RcaDataScript shared globally.
 * @type {?RcaDataScript}
 */
let $store_data;

export default {
    /**
     * Installs the plugin onto the Vue constructor.
     * @param {Vue} Vue The Vue constructor to use with the plugin.
     * @param {RcaData} storeData Plugin options.
     */
    install(Vue, storeData) {
        if (!$store_data) {
            $store_data = new RcaDataScript(storeData);
        }
        /**
         * The Adapter CDN data for the current store.
         * @type {RcaDataScript}
         */
        Vue.prototype.$store_data = $store_data;
    },
};
