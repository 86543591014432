import { jQuery as $ } from "@/core";
import { compareNodesList } from "@/core/utils/comparison_and_validate";
import { globalConsoleLogger } from "@/core/vue/plugins/logger";

/**
 * By default, trigger the mutation observer on ALL changes.
 * See https://developer.mozilla.org/en-US/docs/Web/API/MutationObserverInit for more details.
 */
let defaultObserverOptions = {
    subtree: true,
    childList: true,
    attributes: true,
    attributeFilter: [],
    attributeOldValue: false,
    characterData: false,
    characterDataOldValue: false,
};

function isPromise(value) {
    return Boolean(
        value && typeof value.then === "function" && value[Symbol.toStringTag] === "Promise"
    );
}

export class BaseMutationObserverV2 {
    /**
     * @param {jQuery|string|HTMLElement} element - Element to be observed.
     * @param {Function|DebouncedFunc} callback - The function called every time the observer reports a change.
     * @param {object} [observer] - Observer to be configured.
     * @param {string} observer.name - Name of the observer.
     * @param {Function} observer.startHook - To attach observer.
     * @param {Function} observer.startCondition - Is it ready to start being observed.
     * @param {object} observer.options - Options included in the callback.
     * @param {Logger|console} observer.logger -  The logger to use within this class.
     */
    constructor(
        element,
        callback,
        {
            options = {},
            name = "Base Observer",
            startHook = null,
            startCondition = () => true,
            logger = globalConsoleLogger.extend("BaseMutationObserver"),
        } = {}
    ) {
        this.callback = callback;
        this.observedElementSelector = element;
        this.observer = null;
        this.name = name;
        this.startHook = startHook;
        this.startCondition = startCondition;
        this.observerOptions = { ...defaultObserverOptions, ...options };
        this.$logger = logger;
        this.observer = new MutationObserver(this.handler.bind(this));
        this.isRunning = false;
    }

    /**
     * @param {object} record MutationObserver record.
     * When observer is running and conditions are met it runs the callback.
     */
    async handler(record) {
        if (!this.isRunning) {
            this.isRunning = true;
            if (isPromise(this.callback)) {
                await this.callback(record, this);
            } else {
                this.callback(record, this);
            }
            this.isRunning = false;
        }
    }

    /**
     * @throws Will throw an error if observer is not found.
     * Register or attach  observer if not set. Set up startHook.
     */
    start() {
        try {
            if (this.startHook) {
                this.startHook();
                this.$logger.meta(`${this.name} start hook ran.`);
            }
            const observedElement = $(this.observedElementSelector)?.[0];
            const startConditionResult = this.startCondition();
            if (startConditionResult === true && !!observedElement) {
                this.observer.observe(observedElement, this.observerOptions);
                this.$logger.meta(`${this.name} started.`, {
                    observedElement,
                    observerOptions: this.observerOptions,
                });
            } else {
                if (!startConditionResult) {
                    this.$logger.warn(`${this.name} start condition failed.`);
                }
                if (!observedElement) {
                    this.$logger.meta(`${this.observedElementSelector} not found.`);
                }
            }
        } catch (e) {
            this.$logger.error(`${this.name} unhandled error in start method.`);
            throw e;
        }
    }
    /**
     * @throws Will throw an error if observer is not present.
     * Disconnects the observer.
     */
    stop() {
        try {
            this.observer.disconnect();
            this.$logger.meta(`${this.name} stopped.`);
        } catch (e) {
            this.$logger.error(`${this.name} unhandled error in stop method.`);
            throw e;
        }
    }
    /**
     * @param {Function} callback - Stops the observer, runs the callback, and starts the observer after the callback is called.
     */
    whilePaused(callback) {
        this.$logger.enabled = false;
        this.stop();
        callback();
        this.start();
        this.$logger.enabled = true;
    }
}

/**
 *
 */
export class BaseMutationObserver {
    /**
     * @param {object} [observer] - Observer to be configured.
     * @param {jQuery|string|HTMLElement} observer.observedElementSelector - Element to be observed.
     * @param {Function} observer.callback - The function called every time the observer reports a change.
     * @param {string} observer.name - Name of the observer.
     * @param {object} observer.startHook - To attach observer.
     * @param {boolean} observer.startCondition - Is it ready to start being observed.
     * @param {object} observer.observerOptions - Options included in the callback.
     * @param {Logger|console} observer.logger -  The logger to use within this class.
     */
    constructor({
        observedElementSelector,
        callback,
        observerOptions = null,
        name = "Base Observer",
        startHook = null,
        startCondition = null,
        logger = null,
    }) {
        this.callback = callback;
        this.observedElementSelector = observedElementSelector;
        this.observer = null;
        this.name = name;
        this.startHook = startHook;
        this.startCondition =
            startCondition ||
            function () {
                return true;
            };
        this.observerOptions = observerOptions || defaultObserverOptions;
        this.$logger = logger || globalConsoleLogger.extend(name);
    }
    /**
     * @throws Will throw an error if observer is not found.
     * Register or attach  observer if not set. Set up startHook.
     */
    start() {
        if (!this.observer) {
            this.register();
        }
        try {
            if (this.startHook) {
                this.startHook();
            }
            const observedElement = document.querySelector(this.observedElementSelector);
            const startConditionResult = this.startCondition();
            if (startConditionResult === true && !!observedElement) {
                this.observer.observe(observedElement, this.observerOptions);
                this.$logger.debug(`${this.name} started.`);
            } else {
                if (!startConditionResult) {
                    this.$logger.warn(`${this.name} start condition failed.`);
                }
                if (!observedElement) {
                    this.$logger.debug(`${this.observedElementSelector} not found.`);
                }
            }
        } catch (e) {
            this.$logger.error(`${this.name} unhandled error in start method.`);
            throw e;
        }
    }
    /**
     * @throws Will throw an error if the observer is not present.
     * Register the observer.
     */
    register() {
        try {
            this.observer = new MutationObserver(this.callback);
        } catch (e) {
            this.$logger.error(`${this.name} unhandled error in register method.`);
            throw e;
        }
    }
    /**
     * @throws Will throw an error if observer is not present.
     * Disconnects the observer.
     */
    stop() {
        try {
            this.observer.disconnect();
            this.$logger.debug(`${this.name} stopped.`);
        } catch (e) {
            this.$logger.error(`${this.name} unhandled error in stop method.`);
            throw e;
        }
    }
}

/**
 * @description A class to wrap the `jquery-observe` package. This focuses on creating `MutationObservers` the leverage the power of jQuery.
 * @property {object|string} options - MutationObserver options. See the documentation for more details.
 * @property {string|null} filter - CSS selector used to filter the MutationRecords passed to the callback. Only records matching the filter will be passed to the callback.
 * @property {Function} callback - The function called every time the observer reports a change. This function is passed the MutationRecord of the change.
 * @property {boolean} isStarted - Indicator if the observer has been started.
 * @property {boolean} isDisabled - Indicator if the observer has been disabled. A disabled observer cannot be started.
 * @property {Function} onStart - A function called when the observer is started. No arguments are passed to this.
 * @property {Function} onStop - A function called when the observer is stopped. No arguments are passed to this.
 * @property {Logger|console} $logger - The logger to use within this class.
 */
export class jqueryObserver {
    /**
     * @param {jQuery|string|HTMLElement} source - The element the observer is attached to.
     * @param {Function} callback - The function called every time the observer reports a change. This function is passed the MutationRecord of the change.
     * @param {object} [settings] - Settings to configure the observer.
     * @param {object|string} [settings.options=defaultObserverOptions] - MutationObserver options. See the documentation for more details.
     * @param {string|null} [settings.filter=null] - CSS selector used to filter the MutationRecords passed to the callback. Only records matching the filter will be passed to the callback.
     * @param {Function} [settings.onStart] - A function called when the observer is started. Can be used for logging that observer is started.  No arguments are passed to this.
     * @param {Function} [settings.onStop] - A function called when the observer is stopped. Can be used for logging that observer is stopped.  No arguments are passed to this.
     * @param {Logger} [settings.logger] - The logger to use within this class.
     */
    constructor(
        source,
        callback,
        {
            options = defaultObserverOptions,
            filter = null,
            onStart = () => {},
            onStop = () => {},
            logger = null,
        } = {}
    ) {
        this._source = source;
        this.options = options;
        this.filter = filter;
        this.callback = callback;
        this.isStarted = false;
        this.isDisabled = false;
        this.onStart = onStart;
        this.onStop = onStop;
        this.$logger = logger || globalConsoleLogger.extend(source.toString());
    }
    /**
     * @returns {jQuery} The "source" as a jQuery object.
     */
    get source() {
        return $(this._source);
    }
    /**
     * @param {jQuery|string|HTMLElement} value - The new value for "source".
     */
    set source(value) {
        this._source = value;
    }
    /**
     * @returns {jQuery} A jQuery object corresponding to the "filter" CSS selector.
     */
    get target() {
        return $(this.filter);
    }
    /**
     * @returns {boolean} Indicator if the target element has been found.
     */
    get isTargetFound() {
        return !!this.target.length;
    }
    /**
     * @returns {Array} An array to use as the arguments in `$.observe` or `$.disconnect`.
     */
    get _sig() {
        const handler = (record) => {
            if (this.isStarted && !this.isDisabled) {
                if (record.type === "childList" && !this._isChangeMade(record)) {
                    return;
                }
                this.callback(record, this);
            }
        };
        return [this.options, this.filter, handler].filter((v) => !!v);
    }
    /**
     * @returns {bool} Whether there are nodes present.
     * @param {object} record - Record object.
     */
    _isChangeMade(record) {
        return (
            record.addedNodes.length > 1 ||
            record.removedNodes.length > 1 ||
            !compareNodesList(record.addedNodes, record.removedNodes)
        );
    }
    /**
     * Stops the observer and prevents it from starting again.
     */
    disable() {
        this.isDisabled = true;
        this.stop();
    }
    /**
     * Enables a disabled observer, so it can be started again.
     */
    enable() {
        this.isDisabled = false;
    }
    /**
     * Starts an observer equal to `$(source).observer(options, filter, callback).
     */
    start() {
        if (!this.isStarted && !this.isDisabled) {
            this.$logger?.debug?.(this);
            this.source.observe(...this._sig);
            this.isStarted = true;
            this.onStart();
        }
    }
    /**
     * Stops an observer equal to `$(source).disconnect(options, filter, callback).
     */
    stop() {
        if (this.isStarted) {
            this.$logger?.debug?.(this);
            this.source.disconnect(...this._sig);
            this.isStarted = false;
            this.onStop();
        }
    }
    /**
     * Safely stop the observer by waiting for the DOM to finish loading before disconnecting the observer. This
     * prevents a weird condition where disconnecting an observer can prevent an unrelated observer from registering.
     */
    safeStop() {
        $.ready(this.stop);
    }
    /**
     * @param {Function} callback - Stops the observer, runs the callback, and starts the observer after the callback is called.
     */
    async whilePaused(callback) {
        this.stop();
        const _c = callback();
        if (_c && typeof _c.then === "function" && _c[Symbol.toStringTag] === "Promise") {
            await _c;
        }
        this.start();
    }
}
