<template>
    <div v-if="item.isSubscription && lineElement">
        <!-- Item Text -->
        <dom-overwrite :target="lineElement" />
        <dom-overwrite :target="targets.label" insert="after">
            <cart-line-item-subscription-label
                :is-discounted="item.discount > 0"
                :shipping-frequency="item.shippingFrequency"
                :shipping-unit="item.shippingUnit"
                :charge-frequency="item.chargeFrequency"
                :is-prepaid="item.isPrepaid"
            ></cart-line-item-subscription-label>
        </dom-overwrite>
        <!-- Item Price -->
        <dom-overwrite :target="targets.price" :dom-props="{ innerHTML: item.price.format() }" />
        <!-- Item Total -->
        <dom-overwrite
            :target="targets.total"
            :dom-props="{ innerHTML: item.lineTotal.format() }"
        />
    </div>
</template>

<script>
import CartLineItemSubscriptionLabel from "@/bigcommerce/pages/shared/Cart/CartLineItemSubscriptionLabel";
import DomOverwrite from "@/core/vue/components/DomOverwrite";

export default {
    name: "CartLineItem",
    components: { DomOverwrite, CartLineItemSubscriptionLabel },
    props: {
        item: Object,
    },
    /**
     * @returns {object}  Includes lineElement object and the elementIndex.
     */
    data() {
        return {
            lineElement: null,
            elementIndex: undefined,
        };
    },
    computed: {
        /**
         * @returns {object} Includes label price and total for line element.
         */
        targets() {
            if (!this.lineElement) {
                return {};
            }
            return {
                label: this.lineElement.find(this.getCustomClass("cart_line_item_name")),
                price: this.lineElement.find(this.getCustomClass("cart_line_item_price")),
                total: this.lineElement.find(this.getCustomClass("cart_line_item_total")),
            };
        },
    },
    methods: {
        /**
         * Get line element based on rca class.
         */
        getLineElement() {
            this.$(this.getCustomClass("cart_line_item")).filter((index, el) => {
                if (el.outerHTML.includes(this.item.id) || el.innerHTML.includes(this.item.id)) {
                    this.lineElement = this.$(el);
                    this.elementIndex = index;
                }
            });
        },
    },
    /**
     * Mounts when line element is presented using rca class.
     */
    mounted() {
        this.getLineElement();
    },
};
</script>

<style></style>
